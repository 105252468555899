<template>
  <div class="home">
    <div class="top">
     <dv-decoration-10  class="leftline" />
      <dv-decoration-8  class="leftzhe" />
      <div class="proname">
        <div style="display:flex;justify-content: space-around;">
          <div class="logo"></div>
          <div class="portitle">
            <div style="font-size:40px;">国家管网</div>
              <div style="font-size:20px;">PipeChina</div>
        </div>
        </div>
     <dv-decoration-5  class="topfooter" />

      </div>
      <dv-decoration-8 :reverse="true"   class="rightzhe" />
     <dv-decoration-10  class="rightline" />
     <span class="time">{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span>
     <span class="time1">国家管网北京管道有限公司石家庄输油气分公司</span>
    </div>
    <div class="line">
      <div class="rightbox"></div>
       <div class="rightbox"></div>
        <div class="leftbox"></div>
         <div class="leftbox" style="color:#fff;"></div>
    </div>
    <div class="topbox">
        
        <dv-border-box-1 style="width:50%;">
        <div class="videobox" style="height: 100%;width: 100%">
          <div ref="test" id="video-ezuikit" style="height: 100%;width: 100%" ></div>
        </div>
        </dv-border-box-1>

       
        <div class="databox">
           
            <dv-border-box-1  class="datatop" >
            <div class="datatop">
                <div class="data">
                  <div class="datatitle">当日电量</div>
                  <div class="datanum">{{data.dangri}}</div>
                </div>
                   <div class="data">
                  <div class="datatitle">当月电量</div>
                  <div class="datanum">{{data.dangyue}}</div>
                </div>
                       <div class="data">
                  <div class="datatitle">当年电量</div>
                  <div class="datanum">{{data.dangnian}}</div>
                </div>
                       <div class="data">
                  <div class="datatitle">累计电量</div>
                  <div class="datanum">{{data.leiji}}</div>
                </div>
            </div>
             </dv-border-box-1> 
              <dv-border-box-1  class="datatop" >
            <div class="datatop">
                <div class="data">
                  <div class="datatitle">等效绿植</div>
                  <div class="datanum">{{data.dengxiaoluzhi}}</div>
                </div>
                   <div class="data">
                  <div class="datatitle">CO2减排</div>
                  <div class="datanum">{{data.eryanghuatan}}</div>
                </div>
                  <div class="data">
                  <div class="datatitle">节约标准煤</div>
                  <div class="datanum">{{data.jieyuebiaozhunbei}}</div>
                </div>
                   <div class="data">
                  <div class="datatitle">约节省电费</div>
                  <div class="datanum">{{data.jieshengdianfei}}</div>
                </div>
          
                

            </div>
             </dv-border-box-1> 
          
        </div>
          
         <dv-border-box-1 >
        <div id="topright" style="width:55vw;height:40.8vh;" ></div>
        </dv-border-box-1> 
       </div>
         <div class="topbox">
        
        <dv-border-box-1 style="width:15vw;height:41.8vh;">
        <div id="botleft" style="width:15vw;height:40.8vh;"></div>
        </dv-border-box-1>

         <dv-border-box-1 >
        <div id="botcenter" style="width:40vw;height:40.8vh;"></div>
          </dv-border-box-1>
         <dv-border-box-1 >
        <div id="botright" style="width:40vw;height:40.8vh;"></div>
        </dv-border-box-1> 
       </div>
  </div>
</template>

<script>
import  EZUIKit from 'ezuikit-js';
import { formatTime } from '../utils/index.js'
export default {
  data(){
    return{
      token:'',
      dizhi:'',
   botcenterx:['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          botcentery:[820, 932, 901, 934, 1290, 1330, 1320],
     botrightx:['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      botrighty:[820, 932, 901, 934, 1290, 1330, 1320],
      toprightx:[],
        topline1:[820, 932, 901, 934, 1290, 1330, 1320],
          topline2:[10, 22, 31, 44, 12, 13, 13],
         topline3:[15, 28, 21, 34, 22, 23, 23],
         topline4:[15, 28, 21, 34, 22, 23, 23],
          topline5:[15, 28, 21, 34, 22, 23, 23],
         topline6:[15, 28, 21, 34, 22, 23, 23],
         gonglv:[17, 16, 17,15,18, 20, 19],
         pinlv:[15, 18, 14, 14, 10, 11, 12],
          wendu:[10, 12, 11, 14, 12, 13, 13],
        timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
      botleft:[
        {
          name:'2023',
          value:'50'
        },
         {
          name:'2024',
          value:'100'
        }
      ],
      data:{
        dangri:'40kwh',
        dangnian:'4000kwh',
        dangyue:'3000kwh',
        leiji:'12000kwh',
        dengxiaolvzhi:'20棵',
        eryanghuatan:'20t',
        jieyuebiaozhunbei:'10t',
        jieshengdianfei:'100元'

      },
      dataurl:'http://192.168.0.5:'
      
    }
  },

  
  mounted(){
     this.getdata1(),
      this.getdata2(),
      this.getdata3(),
      this.getdata4(),
      this.getdata5(),
    this.timer= setInterval(() => {
      this.getdata1(),
      this.getdata2(),
      this.getdata3(),
      this.getdata4(),
      this.getdata5()
      
    
    }, 300000); 
     this.initEzviz()
    //  this.timeFn()
    //  this.rightchart()
    //  this.botleftchart()
    //  this.botcenterchart()
    //  this.botrightchart()
  },

  methods:{
    

     initEzviz () {
            let p={
        alarmDeviceSn:'2812262244100247',
        types:1
      }
      // let appKey='b97a6909e07945cc9bfb9eccdbdc8cfa'
      this.$axios.post('https://dpcollector.isiliang.com/prod-api/common/nibianqidata',p).then(res=>{
        
        this.token=res.data.data.yingsytoken
        this.dizhi=res.data.data.yingsyaddress
console.log(this.dizhi,'*************')
     this.player = new EZUIKit.EZUIKitPlayer({
      id: 'video-ezuikit', // 视频容器ID
      accessToken:this.token, //accessToken 的值为你在莹石云平台监控地址的token
      url:this.dizhi,//url 为你莹石云监控的url地址信息,
      width: this.$refs.test.offsetWidth-80,
        height: this.$refs.test.offsetHeight-80,
       autoplay: true,  
      themeData: {
      "header": {
        "color": "#1890ff",
        "activeColor": "#FFFFFF",
        "backgroundColor": "#000000",
        "btnList": [
         
        ]
      },
      "footer": {
        "color": "#FFFFFF",
        "activeColor": "#1890FF",
        "backgroundColor": "#00000021",
        "btnList": [
          {
            "iconId": "play",
            "part": "left",
            "defaultActive": 0,
            "memo": "播放",
            "isrender": 1
          }
        ]
      }
    }


    });
      })
   
 


    },


           getdata1(){
      let p={
        alarmDeviceSn:'2812262244100247',
        types:1
      }
      // this.$axios.post('http://192.168.0.5:8123/common/nibianqiinfo',p).then(res=>{
       
      this.$axios.post('https://dpcollector.isiliang.com/prod-api/common/nibianqiinfo',p).then(res=>{
       if(res.data.code==200){
        console.log(res)
        this.botcenterx=res.data.data.shijian
        this.botcentery=res.data.data.fadianliang
        this.botcenterchart()
       console.log(this.botcenterx,this.botcentery,'%%%%')
       }else{
        this.botcenterx=['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          this.botcentery=[820, 932, 901, 934, 1290, 1330, 1320]
        this.botcenterchart()
       this.$message({
          message: '获取日数据'+res.data.msg,
          type: 'warning'
        });
       }
      })
    },
              getdata2(){
                

      let p={
        alarmDeviceSn:'2812262244100247',
        types:2
      }
      this.$axios.post('https://dpcollector.isiliang.com/prod-api/common/nibianqiinfo',p).then(res=>{
      
       if(res.data.code==200){
       this.botrightx=res.data.data.shijian
       this.botrighty=res.data.data.fadianliang
       this.botrightchart()
      
       }else{
                 this.botrightx=['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
       this.botrighty=[820, 932, 901, 934, 1290, 1330, 1320]
 this.botrightchart()
       this.$message({
          message: '获取月数据'+res.data.msg,
          type: 'warning'
        });
       }
      })
    },
              getdata3(){

      
      let p={
        alarmDeviceSn:'2812262244100247',
        types:3
      }
      this.$axios.post('https://dpcollector.isiliang.com/prod-api/common/nibianqiinfo',p).then(res=>{
   
       if(res.data.code==200){
      
         let arr=[]
                 let dadianliang=res.data.data.fadianliang;
                 let shijian=res.data.data.shijian;

            for(let i =0; i < dadianliang.length;i++){
              let dui={};
              dui.value=dadianliang[i]
              dui.name=shijian[i]
              arr.push(dui)
            }
            this.botleft=arr
            this.botleftchart()
      
       }else{
        
  this.botleftchart()
      //    this.botleftx=res.data.shijian
      //  this.botlefty=res.data.fadianliang
       this.$message({
          message: '获取年数据'+res.data.msg,
          type: 'warning'
        });
       }
      })
    },
                getdata4(){
                
      let p={
        alarmDeviceSn:'2812262244100247',
        types:4
      }
      this.$axios.post('https://dpcollector.isiliang.com/prod-api/common/nibianqiinfo',p).then(res=>{
      
       if(res.data.code==200){
        this.data=res.data.data
       
      
       }else{

      
       this.$message({
          message: '获取数据'+res.data.msg,
          type: 'warning'
        });
       }
      })
    },
              getdata5(){


      let p={
        alarmDeviceSn:'2812262244100247',
        types:5
      }
      this.$axios.post('https://dpcollector.isiliang.com/prod-api/common/nibianqiinfo',p).then(res=>{
      
       if(res.data.code==200){
        
          this.toprightx=res.data.data.shijian
        this.topline1=res.data.data.jiaoliudianliu
          this.topline2=res.data.data.zhiliudianliu1
          this.topline3=res.data.data.zhiliudianliu2
          this.topline4=res.data.data.jiaoliudianya
          this.topline5=res.data.data.zhiliudianya1
          this.topline6=res.data.data.zhiliudianya2
          this.gonglv=res.data.data.shishigonglv
          this.pinlv=res.data.data.dianwangpinlv
          this.wendu=res.data.data.nibianqiwendu

          this.rightchart()
       }else{
                          this.toprightx=['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        this.topline1=[820, 932, 901, 934, 1290, 1330, 1320]
          this.topline2=[10, 22, 31, 44, 12, 13, 13]
          this.topline3= [15, 28, 21, 34, 22, 23, 23]
          this.topline4= [15, 28, 21, 34, 22, 23, 23]
          this.topline5=[15, 28, 21, 34, 22, 23, 23]
          this.topline6=[15, 28, 21, 34, 22, 23, 23]
          this.gonglv= [17, 16, 17,15,18, 20, 19]
          this.pinlv= [15, 18, 14, 14, 10, 11, 12]
          this.wendu= [10, 12, 11, 14, 12, 13, 13]
       this.rightchart()
       this.$message({
          message: '获取日实时数据'+res.data.msg,
          type: 'warning'
        });
       }
      })
    },
   //左下角南丁格尔玫瑰
     botleftchart(){
var chartDom = document.getElementById('botleft');
var myChart = this.$echarts.init(chartDom);
var option;

option = {
  title:{
    show:true,
    text:'年数据',
    textStyle:{
      color:'#fff'
    },
    top:20,
			left:25,
  
  },
   grid:{
    
    left:'5%',
    right:'5%',
    bottom:'10%'
   },
     tooltip:{
    show:true
  },
  legend: {
     textStyle:{
      color:'#fff'
     },
   top:'15%'
  },
  series: [
    {
     
      type: 'pie',
      radius: '50%',
        itemStyle:{
        normal: {
color: function (colors) {
var colorList = [ '#fc8251', '#5470c6', '#91cd77', '#ef6567', '#f9c956', '#75bedc' ];
return colorList[colors.dataIndex];
},},},

      data:this.botleft,
     
    }
  ]
};

 myChart.setOption(option);
 window.addEventListener("resize",function() {
 myChart.resize()
},)
    },
    //右上角
       rightchart(){
       
      var chartDom = document.getElementById('topright');
var myChart = this.$echarts.init(chartDom);
var option;
option = {
  title:{
    show:true,
    text:'当日实时数据',
    textStyle:{
      color:'#fff'
    },
    top:20,
			left:25,
  
  },
     tooltip: {
      show: true,
      trigger: 'axis',
   },
    legend: {
      top: "10%",
      right: "14%",
      itemWidth: 16,
      itemHeight: 10,
      itemGap: 30,
      textStyle: {
        fontSize: 14,
        color: "#B6DCF5",
        padding: [0, 0, 0, 4],
      },
    },
   
    grid: {
    left: '3%',
    right: '10%',
    bottom: '0%',
    top:'20%',
    containLabel: true
  },

  xAxis: {
    type: 'category',
      axisLine: {
                //坐标轴轴线相关设置。数学上的x轴
                show: true,
                lineStyle: {
                    color: '#00FFFF',
                },
            },
    data:this.toprightx
  },
  yAxis: [
    {
      type: 'value',
      name:'A',
       axisLine: {
               
               lineStyle: {
                    color: '#00FFFF',
                },
            },
      splitLine:{
        show:false
      },
    },
       {
      type: 'value',
       position:'right',
       name:'v',
          splitLine:{
        show:false
      },
       axisLine: {
              
               lineStyle: {
                    color: '#00FFFF',
                },
            },
    }
    ,
       {
      type: 'value',
       position:'right',
       name:'kwh',
       offset:60,
          splitLine:{
        show:false
      },
       axisLine: {
                //坐标轴轴线相关设置。数学上的x轴
                show: true,
               lineStyle: {
                    color: '#00FFFF',
                },
            },
    }
    ,
       {
      type: 'value',
       position:'right',
       name:'HZ',
       offset:120,
          splitLine:{
        show:false
      },
       axisLine: {
                //坐标轴轴线相关设置。数学上的x轴
                show: true,
               lineStyle: {
                    color: '#00FFFF',
                },
            },
    }
    ,
       {
      type: 'value',
       position:'right',
       name:'℃',
       offset:180,
          splitLine:{
        show:false
      },
       axisLine: {
                //坐标轴轴线相关设置。数学上的x轴
                show: true,
               lineStyle: {
                    color: '#00FFFF',
                },
            },
    }
  ]
,

  series: [
    {name:'交流电流',
      data:this.topline1,
      type: 'line',
        yAxisIndex:'0',
    
      smooth: true
    },
      {
        name:'直流电流1',
      data:this.topline2,
      yAxisIndex:'0',
      type: 'line',
    
      smooth: true
    }
    ,
      {
        name:'直流电流2',
      data:this.topline3,
      yAxisIndex:'0',
      type: 'line',
     
      smooth: true
    }
    ,
      {
        name:'交流电压',
      data:this.topline4,
      yAxisIndex:'1',
      type: 'line',
      smooth: true
    }
    ,
      {
         name:'直流电压1',
      data:this.topline5,
      yAxisIndex:'1',
      type: 'line',
      smooth: true
    }
    ,
      {
         name:'直流电压2',
      data:this.topline6,
      yAxisIndex:'1',
      type: 'line',
      smooth: true
    }
     ,
      {
        name:'实时功率',
      data:this.gonglv,
      yAxisIndex:'2',
      type: 'line',
      smooth: true
    }
     ,
      {
        name:'电网频率',
      data:this.pinlv,
      yAxisIndex:'3',
      type: 'line',
      smooth: true
    }
     ,
      {
      name:'逆变器温度',
      data:this.wendu,
      yAxisIndex:'4',
      type: 'line',
      smooth: true
    }
  ]
};

 myChart.setOption(option);
 window.addEventListener("resize",function() {
 myChart.resize()
},)
    },


    //底部中间
     botcenterchart(){
      var chartDom = document.getElementById('botcenter');
var myChart = this.$echarts.init(chartDom);
var option;

option = {
  title:{
    show:true,
    text:'当月日数据',
    textStyle:{
      color:'#fff'
    },
    top:20,
			left:25,
  
  },
    grid: {
    left: '8%',
    right: '0%',
    bottom: '3%',
    containLabel: true
  },
    tooltip:{
    show:true
  },
  xAxis: {
    type: 'category',
      axisLine: {
                //坐标轴轴线相关设置。数学上的x轴
                show: true,
               lineStyle: {
                    color: '#00FFFF',
                },
            },
    data: this.botcenterx
  },
  yAxis: {
    type: 'value',
       splitLine:{
        show:false
      },
       axisLine: {
                //坐标轴轴线相关设置。数学上的x轴
                show: true,
                lineStyle: {
                    color: '#00FFFF',
                },
            },
  },
  series: [
    {
      data: this.botcentery,
      type: 'line',
          areaStyle: {
      color: {
         colorStops: [{
            offset: 0,
            color: '#FF0000' // 0% 处的颜色
         },
         {
            offset: 1,
            color: '#FFC0CB' // 100% 处的颜色
         }
         ]
      }
      }
      
      
    }
  ]
};

 myChart.setOption(option);
 window.addEventListener("resize",function() {
 myChart.resize()
},)
    },

      //底部右侧
     botrightchart(){
      var chartDom = document.getElementById('botright');
var myChart = this.$echarts.init(chartDom);
var option;

option = {
  title:{
    show:true,
    text:'当年月数据',
    textStyle:{
      color:'#fff'
    },
    top:20,
			left:25,
  
  },
    grid: {
    left: '8%',
    right: '0%',
    bottom: '3%',
    containLabel: true
  },
  tooltip:{
    show:true
  },
  xAxis: {
    type: 'category',
      axisLine: {
                //坐标轴轴线相关设置。数学上的x轴
                show: true,
                lineStyle: {
                    color: '#00FFFF',
                },
            },
    data: this.botrightx
  },
  yAxis: {
    type: 'value',
       splitLine:{
        show:false
      },
       axisLine: {
                //坐标轴轴线相关设置。数学上的x轴
                show: true,
                lineStyle: {
                    color: '#00FFFF',
                },
            },
  },
 series: [
    {
      data: this.botrighty,
      type: 'line',
      lineStyle: {
        color: '#FFD000',
      },
      // 点
      itemStyle: {
        color: '#FFD000'
      },
      // 平滑 属性
      smooth: true,
      symbol: 'emptyCircle',
      // 区域黄色渐变效果
      areaStyle: {
        normal: {
          color: new this.$echarts.graphic.LinearGradient(
            0, 0, 0, 1,
            [{
              offset: 0,
              color: 'rgba(255, 214, 62, 0.70)'
            },
              {
                offset: 1,
                color: 'rgba(255, 214, 62, 0.10)'
              }
            ],
            false
          ),
          shadowColor: 'rgba(255, 214, 62, 0.10)',
          shadowBlur: 10
        }
      },
    },

  ]
};

 myChart.setOption(option);
 window.addEventListener("resize",function() {
 myChart.resize()
},)
    },
       timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH: mm: ss')
        this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
        this.dateWeek = this.weekday[new Date().getDay()]
      }, 1000)
    },
  },
    beforeDestroy () {
    clearInterval(this.timing)
     clearInterval(this.timer)  // 清除定时器
  this.timer = null  // 定时器的变量赋值null
  },

}
</script>

<style lang='less' scoped>

  .home{
    width: 100vw;
    min-height: 100vh;
    max-height: 100vh;
    overflow: hidden !important;
     background-image: url("../assets/pageBg.png");
     background-size: 100% 100%;

   
    .top{
        display: flex;
        height: 9vh;
         width: 100%;
       
         justify-content: space-evenly;
          .leftline{
             width: 30%;
            
              height: 3rem;
          }
          .leftzhe{
            width:10%;
            height:50px;
          
            margin-top: 2rem;
          }
          .proname{
            width: 15%;
            height: 100%;
             margin-top: 1rem;
            text-align: center;
            font-size: 2rem;
            line-height: 2rem;
        color: white;
      
        .logo{
          background-image:url('../assets/微信图片_20240531113127.png') ;
          background-size: 100% 100%;
          height: 3rem;
         width: 3rem;
        }
     
        .topfooter{
            height: 2rem;
        }
          }
          .rightzhe{
             margin-top: 2rem;
            width:10%;
            height:50px;
          }
         .rightline{
              transform: rotateY(180deg);
              width: 30%;
              height: 3rem;
             
         }
         .time{
          position: absolute;
          top: 4%;
          right: 1%;
          color: white;
          font-size: 20px;
         }
           .time1{
          position: absolute;
          top: 6%;
          right: 1%;
          color: white;
          font-size: 20px;
         }
    }
    .line{
      display: flex;
      justify-content: space-between;
      padding:0 1rem ;
    }
    .leftbox{
      width:23%;
      height: 3vh;
      background: #052b44;
      transform: skew(-40deg,0);
      line-height: 3vh;
      text-align: center;
    }
       .rightbox{
       width:23%;
      height: 3 vh;
      background: #052b44;
      transform: skew(40deg,0);
    }
    .topbox{
       height: 41.8vh;
      width: 100%;
      display: flex;
     
     .videobox{
      padding: 7%;
      overflow: hidden;
      #video-ezuikit{
        width: 100%;
        height: 100%;
      }
     }
     
      .databox{
        width: 20%;
        height: 100%;
      color: white;
       
       .datatop{
        display: flex;
        width: 100%;
        height: 50%;
        margin: auto;
        justify-content: space-between;
    text-align: center;
        flex-wrap: wrap;
        
        
        .data{
          width: 40%;
          margin: auto;
          margin-top: 17%;
          .datatitle{
          
            color: aqua;
          }
          .datanum{
          color: aqua;
         
          }
        }
       }
      }
      
    }
    

    

  }
</style>